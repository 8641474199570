import React from "react";
import { Button, Typography, Box, Dialog, DialogTitle, DialogContent, List, ListItem } from "@mui/material";
import classes from "./MailCheckDialog.module.scss";
import { useTranslation } from "react-i18next";
import MailType from "../../../types/MailType.tsx";

interface Props {
  isOpen: boolean;
  onSubmitClick: () => void;
  onCancelClick: () => void;
  emailList: MailType[];
}

const MailCheckDialog = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog open={props.isOpen} maxWidth={"md"}>
        <DialogTitle>{t("accountCreate.dialog.title")}</DialogTitle>
        <DialogContent>
          <Box className={classes.segment}>
            <Typography>{t("accountCreate.dialog.mailTitle")}</Typography>
            <Typography className={classes.segment_content}>{props.emailList.length !== 0 ? props.emailList[0].title : ''}</Typography>
          </Box>
          <Box className={classes.segment}>
            <Typography>{t("accountCreate.dialog.mailContent")}</Typography>
            <Typography className={classes.segment_content}>{props.emailList.length !== 0 ? props.emailList[0].message : ''}</Typography>
          </Box>
          <Box className={classes.segment}>
            <Typography>{t("accountCreate.dialog.address")}</Typography>
            <List className={classes.segment_content}>
              {props.emailList.map((email) => (
                <ListItem>
                  <Typography>
                    {email.email}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box className={classes.button_container}>
            <Button variant="outlined" onClick={props.onCancelClick}>{t("accountCreate.dialog.button.cancel")}</Button>
            <Button variant="contained" onClick={props.onSubmitClick}>{t("accountCreate.dialog.button.submit")}</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MailCheckDialog;
