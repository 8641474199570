const Color = {
  scope: ["#3FADF7", "#F75D78", "#4AC563"],
  heatmap: ["#349A26", "#92D050", "#FFEC47", "#FEBD14", "#FF4D00"],
  normal: [
    "#42d898",
    "#fb831d",
    "#6f8df7",
    "#ffc73e",
    "#92f0c8",
    "#df9a5e",
    "#b4c1f0",
    "#f0d182",
  ],
  pieChart: [
    "#3fadf7",
    "#9479fc",
    "#d27dfc",
    "#00f2ae",
    "#7db8df",
    "#b8aedf",
    "#d0b3df",
    "#43dfb3",
  ],
};

export default Color;
