import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  LineChart,
  Line,
  Text,
} from "recharts";
import classes from "./LineGraph.module.scss";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Fullscreen, FullscreenExit, PhotoCamera } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import DatamartYoyData from "../../../types/response/DatamartYoyData";
import Color from "../../../types/Color.tsx";
import { ScreenFuatures } from "../../../features/ScreenFuatures.tsx";
import CommonTooltip from "./CommonTooltip.tsx";

type LineGraphProps = {
  title: string;
  data: DatamartYoyData[];
  yLabel: string;
};

const LineGraph: React.FC<LineGraphProps> = (props) => {
  const handle = useFullScreenHandle();
  const [graphData, setGraphData] = useState<object[]>([]);
  const [visibleLines, setVisibleLines] = useState<string[]>([]);
  const [hoverItem, setHoverItem] = useState<string>("");
  const { photoSave } = ScreenFuatures();

  useEffect(() => {
    if (!props.data) return;
    const keys: Array<string> = [];
    const result = {};
    props.data.forEach((value, index) => {
      const yoys = value.yoys;
      keys.push(value.organization_name);
      yoys.forEach((yoy, i) => {
        const date = yoy.month;
        if (date) {
          if (!result[date]) result[date] = { date: date };
          result[date][value.organization_name] = yoy.ratio;
        }
      });
    });
    setGraphData(Object.values(result));
    setVisibleLines(keys);
  }, [props.data]);

  const handleLegendClick = (e) => {
    const dataKey = e.dataKey;
    if (visibleLines.includes(dataKey)) {
      setVisibleLines(visibleLines.filter((line) => line !== dataKey)); // クリックされたラインを非表示にする
    } else {
      setVisibleLines([...visibleLines, dataKey]); // クリックされたラインを表示する
    }
  };

  // Y軸のラベルコンポーネント
  const CustomYAxisLabel = ({ value }) => {
    return (
      <Text
        transform="rotate(-90)"
        textAnchor="middle"
        className={handle.active ? classes.yAxisLabelFull : classes.yAxisLabel}
      >
        {value}
      </Text>
    );
  };

  return (
    <Box className={classes.graphBox}>
      <FullScreen handle={handle} className={classes.fullscreenPanel}>
        {handle.active && (
          <Typography
            sx={{
              textAlign: "left",
            }}
            color="text.secondary"
            gutterBottom
            className={classes.title}
          >
            {props.title}
          </Typography>
        )}
        <ResponsiveContainer
          width="100%"
          height="100%"
          className={handle.active ? classes.chartFull : classes.chart}
          id="lineGraph_"
        >
          <LineChart
            data={graphData}
            height={300}
            margin={{
              bottom: handle.active ? 40 : 0,
              top: handle.active ? 10 : 0,
              right: handle.active ? 40 : 30,
              left: handle.active ? 100 : 0,
            }}
          >
            <XAxis
              dataKey="date"
              angle={-60}
              fontSize={handle.active ? "1.5vh" : "1.5vh"}
              tickMargin={handle.active ? 30 : 20}
              type="category"
              interval={0}
              height={handle.active ? 80 : 55}
            />
            <YAxis
              label={<CustomYAxisLabel value={props.yLabel} />}
              fontSize={handle.active ? "1.5vh" : "1.5vh"}
              className={handle.active ? classes.yAxisFull : ""}
            />
            {visibleLines.map((key, i) => {
              return (
                <Line
                  key={"line_" + i}
                  type="monotone"
                  dataKey={key}
                  stroke={Color.normal[i % Color.normal.length]}
                  opacity={visibleLines.includes(key) ? "1" : "0"}
                  activeDot={{
                    onPointerOver: () => {
                      setHoverItem(key);
                    },
                  }}
                />
              );
            })}
            ;
            <Tooltip
              labelStyle={{ fontSize: "1.5vh" }}
              itemStyle={{ fontSize: "1.5vh", WebkitTextStroke: "0.1px #000" }}
              content={(props) => <CommonTooltip {...props} item={hoverItem} />}
            />
            <Legend
              onClick={handleLegendClick}
              wrapperStyle={{
                fontSize: handle.active ? "1.5vh" : "1.5vh",
              }}
            />
          </LineChart>
        </ResponsiveContainer>
        <Box
          className={classes.buttonDiv}
          style={{
            right: handle.active ? "1%" : "0%",
            bottom: handle.active ? "0%" : "-15%",
          }}
        >
          <div
            onClick={() =>
              photoSave(
                document.getElementById("lineGraph_"),
                props.title,
                handle.active
              )
            }
            className={classes.screenButton}
          >
            <PhotoCamera />
          </div>
          {!handle.active ? (
            <div onClick={handle.enter} className={classes.fullscreen}>
              <Fullscreen />
            </div>
          ) : (
            <div onClick={handle.exit} className={classes.fullscreenExit}>
              <FullscreenExit />
            </div>
          )}
        </Box>
      </FullScreen>
    </Box>
  );
};

export default LineGraph;
