import { useContext, useState } from "react";
import AccountCreateApi from "../features/api/AccountCreateApi.tsx";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import { PrepareCreateAccount } from "../types/response/PrepareCreateAccountResponse.tsx";
import MailType from "../types/MailType.tsx";

export const useAccountCreate = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const [accountCreateApiSuccess, setAccountCreateApiSuccess] = useState(false);
  const [accountCreateApiLoading, setAccountCreateApiLoading] = useState(false);
  // 顧客ファイル
  const [organizationFile, setOrganizationFile] = useState<File | undefined>();
  // カレンダー入力フォーマット
  const [calendarFile, setCalendarFiles] = useState<File | undefined>();
  // ユーザ入力フォーマット
  const [userFile, setUserFiles] = useState<File | undefined>();
  // 契約入力フォーマット
  const [contractFile, setContractFiles] = useState<File | undefined>();
  const [isMailModalOpen, setIsMailModalOpen] = useState<boolean>(false);
  const [emailList, setEmailList] = useState<MailType[]>([]);

  /**
   * アカウント作成準備API成功時
   */
  const onPrepareSuccess = (data: PrepareCreateAccount) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setAccountCreateApiSuccess(true);
    setAccountCreateApiLoading(false);
    if (data.temp_email_list.length !== 0) {
      setEmailList(data.temp_email_list);
      setIsMailModalOpen(true);
    }
  };

  /**
   * アカウント作成準備API失敗時
   */
  const onPrepareError = (error) => {
    onCommonError(error);
    setAccountCreateApiSuccess(false);
    setAccountCreateApiLoading(false);
  };

  /**
   * アカウント作成API成功時
   */
  const onCreateSuccess = (data) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setAccountCreateApiSuccess(true);
    setAccountCreateApiLoading(false);
    setIsMailModalOpen(false);
  };

  /**
   * アカウント作成API失敗時
   */
  const onCreateError = (error) => {
    onCommonError(error);
    setAccountCreateApiSuccess(false);
    setAccountCreateApiLoading(false);
    setIsMailModalOpen(false);
  };

  /**
   * アカウント削除API成功時
   */
  const onDeleteSuccess = (data) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setAccountCreateApiSuccess(true);
    setAccountCreateApiLoading(false);
    setIsMailModalOpen(false);
  };

  /**
   * アカウント削除API失敗時
   */
  const onDeleteError = (error) => {
    onCommonError(error);
    setAccountCreateApiSuccess(false);
    setAccountCreateApiLoading(false);
    setIsMailModalOpen(false);
  };

  /**
   * アカウント発行準備API
   */
  const prepareCreateAccountsApi = () => {
    setAccountCreateApiLoading(true);
    exePrepareCreateAccounts.mutate({
      organizationFile,
      calendarFile,
      userFile,
      contractFile,
    });
  };

  /**
   * アカウント発行API
   */
  const createAccountsApi = () => {
    setAccountCreateApiLoading(true);
    exeCreateAccounts.mutate(emailList.map((email) => email.email_id));
  };

  /**
   * アカウント削除API
   */
  const deleteAccountsApi = () => {
    setAccountCreateApiLoading(true);
    exeDeleteAccounts.mutate(emailList.map((email) => email.email_id));
  };

  // アカウント発行準備API実行
  const exePrepareCreateAccounts = AccountCreateApi.PrepareCreateAccounts(
    onPrepareSuccess,
    onPrepareError
  );

  // アカウント発行API実行
  const exeCreateAccounts = AccountCreateApi.CreateAccounts(
    onCreateSuccess,
    onCreateError
  );

  // 一時保存アカウント削除API実行
  const exeDeleteAccounts = AccountCreateApi.DeleteAccounts(
    onDeleteSuccess,
    onDeleteError
  );

  return {
    prepareCreateAccountsApi,
    createAccountsApi,
    deleteAccountsApi,
    accountCreateApiLoading,
    accountCreateApiSuccess,
    organizationFile,
    setOrganizationFile,
    calendarFile,
    setCalendarFiles,
    userFile,
    setUserFiles,
    contractFile,
    setContractFiles,
    emailList,
    isMailModalOpen,
  };
};
