import React, { useEffect } from "react";
import { format } from "date-fns";
import { Box, Button, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import classes from "./NoticeManagementForm.module.scss";
import { useTranslation } from "react-i18next";
import { useNotices } from "../../../hooks/useNotices.tsx";
import { useNavigate } from "react-router-dom";

const NoticeManagementForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { getNoticesApi, deleteNoticeApi, noticeList, handleCheck, checkedList } = useNotices()

  useEffect(() => {
    getNoticesApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreateBtnClick = () => {
    navigate("/admin/notice-create")
  }

  return (
    <Stack className={classes.container}>
      <Typography variant="h5">{t("noticeManagement.noticeHistory")}</Typography>
      <TableContainer className={classes.table_container}>
        <Table aria-label="notice management table" className={classes.table}>
          <TableHead className={classes.table_header}>
            <TableRow>
              <TableCell align="center">{t("noticeManagement.table.edit")}</TableCell>
              <TableCell align="left">{t("noticeManagement.table.date")}</TableCell>
              <TableCell align="left">{t("noticeManagement.table.category")}</TableCell>
              <TableCell align="left">{t("noticeManagement.table.body")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {noticeList ? noticeList.map((notice) => (
              <TableRow
                key={notice.id}
                className={classes.table_row}
                onClick={() => handleCheck(notice.id)}
              >
                <TableCell align="center" className={classes.checkbox_column}>
                  <Checkbox
                    color="primary"
                    checked={checkedList.includes(notice.id)}
                    inputProps={{
                      'aria-label': 'select notice',
                    }}
                  />
                </TableCell>
                <TableCell align="left" className={classes.small_column}>{format(new Date(notice.released_at_str), 'yyyy/MM/dd').replace(/\/0(\d)/, '/$1')}</TableCell>
                <TableCell align="left" className={classes.small_column}>{notice.category}</TableCell>
                <TableCell align="left">{notice.content}</TableCell>
              </TableRow>
            )) : <></>}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.button_container}>
        <Button variant="contained" onClick={handleCreateBtnClick}>{t("noticeManagement.button.create")}</Button>
        <Button variant="outlined" onClick={deleteNoticeApi}>{t("noticeManagement.button.delete")}</Button>
      </Box>
    </Stack>
  );
};

export default NoticeManagementForm;
