import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";

const CommonTooltip: React.FC<
  Partial<TooltipProps<any, any>> & { item: string }
> = ({ active, payload, label, item }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    const focusedData = payload.find(
      (data) => data.value !== undefined && data.dataKey === item
    );
    const color = focusedData?.color; // 該当する色を取得
    if (focusedData) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "0.1px solid #ccc",
            padding: "0px 1vh",
            position: "relative",
            // WebkitTextStroke: "0.1px #000",
            color: color,
            zIndex: 50000,
            fontSize: "2vh",
          }}
        >
          <p>{`${label}`}</p>
          <p>{`${focusedData.name}: ${
            focusedData.value
              ? focusedData.value.toLocaleString()
              : focusedData.value
          }`}</p>
        </div>
      );
    }
  }
  return "";
};

export default CommonTooltip;
