import React, { useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import classes from "./ManualField.module.scss";
import { useFile } from "../../../hooks/useFile.tsx";
import { FileTypeEnum } from "../../../types/enum/FileTypeEnum.tsx";
import { useTranslation } from "react-i18next";
import DataInputFeatures from "../../../features/DataInputFeatures.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import MessageType from "../../../types/Message.tsx";

const ManualField = () => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const Message = MessageType();
  const { downloadFiles, isLoading } = useFile();
  const { getOrganizationId } = DataInputFeatures();
  const handleClick = (event) => {
    if (isLoading) {
      return;
    }
    const oragnizationId = getOrganizationId();
    if (!oragnizationId) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_ORGANIZATION_DATA);
      return;
    }
    downloadFiles(
      ["summary", "product", "facility"].includes(appContext?.sideItem || "")
        ? FileTypeEnum.manual_product
        : FileTypeEnum.manual_organization,
      oragnizationId
    );
  };
  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1 }}
          className={classes.iconTitle}
        >
          {t("header.manual")}
        </Typography>
      </IconButton>
    </>
  );
};

export default ManualField;
