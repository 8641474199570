import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import classes from "./AccountCreateForm.module.scss";
import { useTranslation } from "react-i18next";
import { useAccountCreate } from "../../../hooks/useAccountCreate.tsx";
import FileInputField from "../../fields/AccountCreate/FileUploadField.tsx";
import MailCheckDialog from "./MailCheckDialog.tsx";

const AccountCreateForm = () => {
  const { t } = useTranslation();
  const {
    prepareCreateAccountsApi,
    createAccountsApi,
    deleteAccountsApi,
    organizationFile,
    setOrganizationFile,
    calendarFile,
    setCalendarFiles,
    userFile,
    setUserFiles,
    contractFile,
    setContractFiles,
    emailList,
    isMailModalOpen
  } = useAccountCreate();
  const isActive: boolean = organizationFile && calendarFile && userFile && contractFile ? true : false;

  const handleSubmit = () => prepareCreateAccountsApi();

  const InputFieldList: {
    createTitle: string;
    formatList: {
      title: string;
      file: File | undefined;
      onDrop: (file: File) => void;
    }[]
  }[] = [
    {
      createTitle: "accountCreate.customer.title",
      formatList: [
        {
          title: "accountCreate.customer.format.customer",
          file: organizationFile,
          onDrop: (file) => setOrganizationFile(file)
        },
        {
          title: "accountCreate.customer.format.calendar",
          file: calendarFile,
          onDrop: (file) => setCalendarFiles(file)
        }
      ]
    },
    {
      createTitle: "accountCreate.user.title",
      formatList: [
        {
          title: "accountCreate.user.format.user",
          file: userFile,
          onDrop: (file) => setUserFiles(file)
        }
      ]
    },
    {
      createTitle: "accountCreate.contract.title",
      formatList: [
        {
          title: "accountCreate.contract.format.contract",
          file: contractFile,
          onDrop: (file) => setContractFiles(file)
        }
      ]
    }
  ]

  return (
    <>
      <Stack className={classes.container}>
        {
          InputFieldList.map((createSegment) => (
            <Stack key={createSegment.createTitle} className={classes.create_segment}>
              <Typography className={classes.create_title} variant="h6">
                {t(createSegment.createTitle)}
              </Typography>
              {
                createSegment.formatList.map((formatItem) => (
                  <Stack key={formatItem.title} className={classes.format_segment}>
                    <Typography className={classes.format_title}>
                      {t(formatItem.title)}
                    </Typography>
                    <FileInputField file={formatItem.file} onDrop={formatItem.onDrop} />
                  </Stack>
                ))
              }
            </Stack>
          ))
        }
        <Button variant="contained" onClick={handleSubmit} disabled={!isActive}>{t("accountCreate.accountCreate")}</Button>
      </Stack>
      <MailCheckDialog
        isOpen={isMailModalOpen}
        onSubmitClick={createAccountsApi}
        onCancelClick={deleteAccountsApi}
        emailList={emailList}
      />
    </>
  );
};

export default AccountCreateForm;
