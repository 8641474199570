import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import classes from "./FacilityLayout.module.scss";
import PCFHeatMapForm from "../../forms/Facility/PCFHeatMapForm.tsx";
import PCFSingleDisplayForm from "../../forms/Facility/PCFSingleDisplayForm.tsx";
import PCFLineGraphForm from "../../forms/Facility/PCFLineGraphForm.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import { useDatamartEquipment } from "../../../hooks/useDatamartEquipment.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTarget } from "../../../hooks/useTarget.tsx";
import DatamartFacilityContext from "../../../contexts/DatamartFacilityContext.tsx";

type FacilityLayoutProps = {};

const FacilityLayout: React.FC<FacilityLayoutProps> = (props) => {
  const searchContext = useContext(DatamartSearchContext);
  const { saveEquipmentData, selectCells } = useContext(
    DatamartFacilityContext
  );
  const { getDatamartEquipmentApi, datamartData, isLoading } =
    useDatamartEquipment();
  const { getTargetApi, targetLoading } = useTarget();
  const [emissionValue, setEmissionValue] = useState("emission");

  useEffect(() => {
    if (!searchContext?.exeSearch) return;
    // データマート取得API
    getDatamartEquipmentApi();
    // 目標値取得API
    getTargetApi();
    searchContext.setSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext?.exeSearch]);

  const handleSelectEmissionSwitching = (value: string) => {
    setEmissionValue(value);
  };

  const getHeatMapData = () => {
    if (emissionValue === "pcf") {
      return selectCells.length
        ? saveEquipmentData.pcf_heatmap
        : datamartData.pcf_heatmap;
    } else {
      return selectCells.length
        ? saveEquipmentData.emission_heatmap
        : datamartData.emission_heatmap;
    }
  };

  return (
    <>
      {!isLoading && !targetLoading && (
        <>
          <Box className={classes.pcfHeatMap}>
            <PCFHeatMapForm
              data={getHeatMapData()}
              handleSelectEmissionSwitching={handleSelectEmissionSwitching}
              emissionValue={emissionValue}
            />
          </Box>
          <Box className={classes.pcf} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <PCFSingleDisplayForm
                  data={datamartData.statistics}
                  pdsData={datamartData.pds_table}
                  emissionValue={emissionValue}
                />
              </Grid>
              <Grid item xs={8}>
                <PCFLineGraphForm
                  data={datamartData}
                  emissionValue={emissionValue}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {(isLoading || targetLoading) && <CircularProgressDialog />}
    </>
  );
};

export default FacilityLayout;
