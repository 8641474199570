import { useContext } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";

export const ApiCommon = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);

  /**
   * API共通エラー処理
   */
  const onCommonError = (error, message?) => {
    const response = error.response;
    const errorMessage = message ? message : Message.ERROR_SYSTEM_ERROR;
    if (!response) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(errorMessage);
      return;
    }
    switch (response.status) {
      case 400:
      case 401:
      case 422:
      case 403:
      case 404:
        appContext?.setErrorOpen(true);
        appContext?.setAlertMessage(errorMessage);
        break;
    }
  };

  return { onCommonError };
};
