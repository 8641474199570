const CommonUtils = {
  /**
   * ログイン状態確認
   * @returns true: ログイン false: 未ログイン
   */
  isLogin: () => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("id_token")
    ) {
      return true;
    }
    return false;
  },
  /**
   *
   * @param obj オブジェクト型空判定
   * true: データなし false: データあり
   */
  isObjectEmpty: (obj) => {
    return Object.keys(obj).length === 0;
  },

  /**
   * YYYY-MM形式からデータ型データを取得
   * @param date(YYYY-MM)
   * @returns Date型
   */
  toDateYYYYMM: (date: string) => {
    if (date) {
      const yyyy = Number(date.substring(0, 4));
      const mm = Number(date.substring(4, 6)) - 1;
      return new Date(yyyy, mm);
    }
    return new Date();
  },

  /**
   * DateデータをYYYY-MM-DD形式で返却
   * @param date
   * @returns YYYY-MM-DD
   */
  formatDateMMDD_day: (date: Date) => {
    const days = [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
    ];
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const day = date.getDay();
    return `${mm}月${dd}日 ${days[day]}`;
  },

  /**
   * DateデータをYYYY-MM-DD形式で返却
   * @param date
   * @returns YYYY-MM-DD
   */
  formatDateYYYYMMDD: (date: Date) => {
    const yyyy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  },

  /**
   * DateデータをYYYY/MM/DD形式で返却
   * @param date
   * @returns YYYY/MM/DD
   */
  formatDateYYYYMMDD_slash: (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const yyyy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}/${mm}/${dd}`;
  },

  /**
   * Dateデータをhh:mm:ss形式で返却
   * @param date
   * @returns hh:mm:ss
   */
  formatDatehhmmss: (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const hh = String(date.getHours());
    const mm = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getMilliseconds()).padStart(2, "0");
    return `${hh}:${mm}:${ss}`;
  },

  /**
   * DateデータをYYYYMMDDhhmmss形式で返却
   * @param date
   * @returns YYYYMMDDhhmmss
   */
  formatDayYYYYMMDDhhmmss: (date: Date) => {
    const yyyy = String(date.getFullYear());
    const MM = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours());
    const mm = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getMilliseconds()).padStart(2, "0");
    return `${yyyy}${MM}${dd}${hh}${mm}${ss}`;
  },
};

export default CommonUtils;
