import { Location } from "react-router-dom";

const CommonUtils = {
  /**
   * ログイン状態確認
   * @returns true: ログイン false: 未ログイン
   */
  isLogin: () => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("id_token")
    ) {
      return true;
    }
    return false;
  },
  /**
   *
   * @param obj オブジェクト型空判定
   * true: データなし false: データあり
   */
  isObjectEmpty: (obj) => {
    return Object.keys(obj).length === 0;
  },

  /**
   * YYYY-MM形式からDate型データを取得
   * @param date(YYYY-MM)
   * @returns Date型
   */
  toDateYYYY_MM: (date: string) => {
    if (date) {
      const yyyy = Number(date.substring(0, 4));
      const mm = Number(date.substring(5, 7)) - 1;
      return new Date(yyyy, mm);
    }
    return new Date();
  },

  /**
   * DateデータをYYYY-MM-DD形式で返却
   * @param date
   * @returns YYYY-MM-DD
   */
  formatDateMMDD_day: (date: Date) => {
    const days = [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
    ];
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const day = date.getDay();
    return `${mm}月${dd}日 ${days[day]}`;
  },

  /**
   * DateデータをYYYY-MM-DD形式で返却
   * @param date
   * @returns YYYY-MM-DD
   */
  formatDateYYYYMMDD: (date: Date) => {
    const yyyy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  },

  /**
   * DateデータをYYYY/MM/DD形式で返却
   * @param date
   * @returns YYYY/MM/DD
   */
  formatDateYYYYMMDD_slash: (dateString: string) => {
    if (!dateString) return "";
    if (dateString.indexOf("Z") === -1) dateString += "Z";
    const date = new Date(dateString);
    const localDate = date.toLocaleDateString("ja-JP", {
      timeZone: "Asia/Tokyo",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return localDate;
  },

  /**
   * Dateデータをhh:mm:ss形式で返却
   * @param date
   * @returns hh:mm:ss
   */
  formatDatehhmmss: (dateString: string) => {
    if (!dateString) return "";
    if (dateString.indexOf("Z") === -1) dateString += "Z";
    const date = new Date(dateString);
    const localTime = date.toLocaleTimeString("ja-JP", {
      timeZone: "Asia/Tokyo",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    return localTime;
  },

  /**
   * DateデータをYYYYMMDDhhmmss形式で返却
   * @param date
   * @returns YYYYMMDDhhmmss
   */
  formatDayYYYYMMDDhhmmss: (date: Date) => {
    const yyyy = String(date.getFullYear());
    const MM = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours());
    const mm = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getMilliseconds()).padStart(2, "0");
    return `${yyyy}${MM}${dd}${hh}${mm}${ss}`;
  },

  /**
   * 検索保持パス先判定処理
   * @param location
   * true マスターデータ更新あり false マスターデータ更新なし
   */
  checkSearchPath: (location: Location<any>) => {
    if (location.state) {
      // パス遷移前のパス名
      const fromPath = location.state.from;
      // 現在のパス名
      const pathName = location.pathname;
      switch (fromPath) {
        case "/summary":
          if (pathName === "/product") return false;
          break;
        case "/product":
          if (pathName === "/summary") return false;
          break;
      }
    }
    return true;
  },

  /**
   * 色の濃淡を設定する関数
   * percentの値がマイナスで濃くなり、プラスで薄くなる
   * @param color ：対象カラー
   * @param percent ：パーセント
   * @returns
   */
  shadeColor: (color: string, percent: number) => {
    let usePound = false;
    if (color[0] === "#") {
      color = color.slice(1);
      usePound = true;
    }

    const num = parseInt(color, 16);

    let r = (num >> 16) + percent;
    let g = ((num >> 8) & 0x00ff) + percent;
    let b = (num & 0x0000ff) + percent;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    return (
      (usePound ? "#" : "") +
      r.toString(16).padStart(2, "0") +
      g.toString(16).padStart(2, "0") +
      b.toString(16).padStart(2, "0")
    );
  },

  /**
   * 数値データをカンマ表示で返す
   * @param value
   * @returns
   */
  formatNumber: (value: number) => {
    const valueStr = value.toString();
    const parts = valueStr.split(".");
    const integerPart = parseInt(parts[0], 10).toLocaleString();
    return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
  },

  /**
   * 数値桁数（整数のみ）取得
   * @param value
   * @returns
   */
  getIntegerPartDigits: (value: number | string) => {
    if (value === null || value === undefined) return 0;
    const integerPart = value.toString().split(".")[0];
    return integerPart.length;
  },

  /**
   * 制限桁数に超えた場合にそれ以上の文言を省略し「…」で表現する
   * @param value 文字データ
   * @param digit 制限桁数
   */
  dispLabelOmisssion: (value: string, digit: number) => {
    if (value && value.length > digit) {
      return value.substring(0, digit) + "…";
    }
    return value;
  },
};

export default CommonUtils;
