import React, { useState } from "react";
import classes from "./EmissionSwitchingField.module.scss";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

type EmissionSwitchingFieldProps = {
  handleSelect?: (value: string) => void;
  value?: string;
};

const EmissionSwitchingField: React.FC<EmissionSwitchingFieldProps> = (
  props
) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.value ? props.value : "emission");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (props.handleSelect) {
      props.handleSelect(newValue);
    }
  };

  return (
    <>
      <Box className={classes.displaySwitching}>
        <Box className={classes.label}>
          {t("facility.displaySwitching.title")}
        </Box>
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          className={classes.toggleButtonGroup}
        >
          <ToggleButton value="emission" className={classes.toggleButtonGroup}>
            {t("facility.displaySwitching.emission")}
          </ToggleButton>
          <ToggleButton value="pcf" className={classes.toggleButtonGroup}>
            {t("facility.displaySwitching.pcf")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

export default EmissionSwitchingField;
