import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";

const BesideBarTooltip: React.FC<
  Partial<TooltipProps<any, any>> & { item: string } & { ratioKey: string }
> = ({ active, payload, label, item, ratioKey }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    const ratioData = payload.find(
      (data) => data.value !== undefined && data.dataKey === ratioKey
    );
    let focusedData;
    if (ratioData?.payload) {
      focusedData = ratioData?.payload[item];
    }
    const color = ratioData?.color; // 該当する色を取得
    if (ratioData) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "0.1px solid #ccc",
            padding: "0px 1vh",
            position: "relative",
            // WebkitTextStroke: "0.1px #000",
            color: color,
            zIndex: 50000,
            fontSize: "2vh",
            top: "-10vh",
          }}
        >
          <p>{`${label}`}</p>
          <p>{`${ratioData.name}: ${
            focusedData ? focusedData.toLocaleString() : focusedData
          }`}</p>
          <p>{`${t("company.toolChip")}: ${
            ratioData.value ? ratioData.value.toLocaleString() : ratioData.value
          }%`}</p>
        </div>
      );
    }
  }
  return "";
};

export default BesideBarTooltip;
