import { useQuery } from "react-query";
import axios from "axios";
import MasterDataResponse from "../../types/response/MasterDataResponse.tsx";

const MasterDataApi = {
  /**
   * マスターデータ取得API
   * @param request
   */
  GetMasterData: (onSuccess, onError) => {
    return useQuery(
      "master",
      async () => {
        const response = await axios.get<MasterDataResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/masterdata/",
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default MasterDataApi;
