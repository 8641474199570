import React, { useContext, useEffect, useState } from "react";
import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  BarChart,
  TooltipProps,
} from "recharts";
import classes from "./BesideBarGraph.module.scss";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Fullscreen, FullscreenExit, PhotoCamera } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import DatamartPcfChart from "../../../types/response/DatamartPcfChart";
import Color from "../../../types/Color.tsx";
import { ScreenFuatures } from "../../../features/ScreenFuatures.tsx";
import { useTranslation } from "react-i18next";
import DatamartProductContext from "../../../contexts/DatamartProductContext.tsx";
import RequestCode from "../../../types/request/RequestCode.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import BesideBarTooltip from "./BesideBarTooltip.tsx";

type BesideBarGraphProps = {
  title: string;
  data: DatamartPcfChart;
  type: string;
};

const BesideBarGraph: React.FC<BesideBarGraphProps> = (props) => {
  const { t } = useTranslation();
  const handle = useFullScreenHandle();
  const { photoSave } = ScreenFuatures();
  const [graphData, setGraphData] = useState<object>({});
  const [graphKeys, setGraphKeys] = useState<string[]>([]);
  const [hoverItem, setHoverItem] = useState<string>("");
  const { setSelectType, selectCells, setSelectCells, setBesideBarSearch } =
    useContext(DatamartProductContext);

  const getColor = (index: number) => {
    return Color.normal[index % Color.normal.length];
  };

  /**
   * グラフ表示用データ生成処理
   */
  useEffect(() => {
    if (!props.data) return;
    const result = {};
    const keys: Array<string> = [];
    if (!props.data.data) return;

    props.data.data.forEach((value, index) => {
      if (!result[value.y_axis_key]) {
        result[value.y_axis_key] = {};
        result[value.y_axis_key]["y_axis_key"] = value.y_axis_key;
      }
      if (
        result[value.y_axis_key][value.x_axis_key] !== null &&
        result[value.y_axis_key][value.x_axis_key] !== undefined
      ) {
        result[value.y_axis_key][value.x_axis_key] += value.composition_ratio;
        result[value.y_axis_key][value.x_axis_key + "_value"] += value.value;
      } else {
        result[value.y_axis_key][value.x_axis_key] = value.composition_ratio;
        result[value.y_axis_key][value.x_axis_key + "_value"] = value.value;
      }
      if (keys.indexOf(value.x_axis_key) === -1) keys.push(value.x_axis_key);
    });
    setGraphData(result);
    setGraphKeys(keys);
  }, [props.data]);

  /**
   * グラフセル選択処理
   * @param data
   */
  const handleCellClick = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    key: string
  ) => {
    event.stopPropagation();
    if (!props.data.data) return;
    const selectData: RequestCode[] = [];
    props.data.data.forEach((data) => {
      if (data.x_axis_key === key) {
        selectData.push({
          code: data.x_axis_code,
          name: data.x_axis_key,
        });
      }
    });
    selectCells[props.type] = selectData;
    setSelectCells({ ...selectCells });
    setSelectType(props.type);
    setBesideBarSearch(true);
  };

  /**
   * グラフ枠選択処理
   * @param data
   */
  const handleBoxClick = (e) => {
    const elm = document.getElementById("besideBarButton_" + props.type);
    if (elm && !elm.contains(e.target) && selectCells[props.type]) {
      delete selectCells[props.type];
      setSelectCells({ ...selectCells });
      setBesideBarSearch(true);
      setSelectType("");
    }
  };

  return (
    <div className={classes.graphBox} onClick={handleBoxClick}>
      <FullScreen handle={handle} className={classes.fullscreenPanel}>
        {handle.active && (
          <Typography
            sx={{
              textAlign: "left",
            }}
            color="text.secondary"
            gutterBottom
            className={classes.title}
          >
            {props.title}
          </Typography>
        )}
        <ResponsiveContainer
          className={handle.active ? classes.chartFull : classes.chart}
          id={"besideBarGraph_" + props.title}
        >
          <BarChart data={Object.values(graphData)} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              fontSize={handle.active ? "1.0vw" : "0.6vw"}
              tickMargin={10}
              label={{
                value: t("product.besideBarGraph.xLabel"),
                position: "insideBottom",
                offset: handle.active ? 0 : 0,
                style: { fontSize: handle.active ? "1.0vw" : "0.6vw" },
              }}
              height={handle.active ? 60 : 40}
            />
            <YAxis
              dataKey="y_axis_key"
              type="category"
              fontSize={handle.active ? "1.0vw" : "0.6vw"}
              className={handle.active ? classes.yAxisLabelFull : ""}
            />
            <Tooltip
              labelStyle={{ fontSize: "1.5vh" }}
              itemStyle={{ fontSize: "1.5vh", WebkitTextStroke: "0.1px #000" }}
              content={(props) => (
                <BesideBarTooltip
                  {...props}
                  item={hoverItem + "_value"}
                  ratioKey={hoverItem}
                />
              )}
            />
            {graphKeys.map((key, index) => {
              return (
                <Bar
                  dataKey={key}
                  stackId="a"
                  fill={getColor(index)}
                  stroke={
                    selectCells[props.type] &&
                    selectCells[props.type].find((val) => {
                      return val.name === key;
                    })
                      ? CommonUtils.shadeColor(getColor(index), -20)
                      : "none"
                  }
                  strokeWidth={
                    selectCells[props.type] &&
                    selectCells[props.type].find((val) => {
                      return val.name === key;
                    })
                      ? "0.5vh"
                      : "0.5vh"
                  }
                  onClick={(
                    data,
                    i,
                    e: React.MouseEvent<SVGElement, MouseEvent>
                  ) => handleCellClick(e, key)}
                  onMouseOver={() => {
                    setHoverItem(key);
                  }}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
        <Box
          id={"besideBarButton_" + props.type}
          className={classes.buttonDiv}
          style={{
            right: handle.active ? "3%" : "-11%",
            bottom: handle.active ? "0%" : "-18%",
          }}
        >
          <div
            onClick={() =>
              photoSave(
                document.getElementById("besideBarGraph_" + props.title),
                props.title,
                handle.active
              )
            }
            className={classes.screenButton}
          >
            <PhotoCamera />
          </div>
          {!handle.active ? (
            <div onClick={handle.enter} className={classes.fullscreen}>
              <Fullscreen />
            </div>
          ) : (
            <div onClick={handle.exit} className={classes.fullscreenExit}>
              <FullscreenExit />
            </div>
          )}
        </Box>
      </FullScreen>
    </div>
  );
};

export default BesideBarGraph;
