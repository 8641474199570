import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./SpreadSheet.module.scss";
import DatamartTableData from "../../../types/response/DatamartTableData";
import { useTranslation } from "react-i18next";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";

type SpreadSheetProps = {
  data: DatamartTableData[];
  multiple: number;
};

const SpreadSheet: React.FC<SpreadSheetProps> = (props) => {
  const [tableData, setTableData] = useState<object[]>([]);
  const [monthKeys, setMonthKeys] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.data) return;
    const keys: Array<string> = [];
    let results: Array<object> = [];
    props.data.forEach((value, index) => {
      const result: object = {};
      const months = value.months;
      months.forEach((month, i) => {
        const dateKey = month.month;
        if (!result[month.fiscal_year]) {
          result[month.fiscal_year] = {};
          result[month.fiscal_year]["name"] = value.organization_name;
          result[month.fiscal_year]["unit"] = value.unit_type_name;
          result[month.fiscal_year]["year"] = month.fiscal_year;
        }
        if (keys.indexOf(dateKey) === -1) keys.push(dateKey);
        result[month.fiscal_year][dateKey] = month.amount;
      });
      results = [...results, ...Object.values(result)];
    });
    setTableData(results);
    setMonthKeys(keys);
  }, [props.data]);

  return (
    <TableContainer component={Paper} style={{ height: "100%" }}>
      <Table
        size="small"
        sx={{ minWidth: 1400, tableLayout: "fixed", width: "100%" }}
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell
              sx={{ width: "8%" }}
              className={classes.tableCell}
            ></TableCell>
            <TableCell
              sx={{ width: "2%" }}
              className={classes.tableCell}
            ></TableCell>
            <TableCell sx={{ width: "6%" }} className={classes.tableCell}>
              {t("company.spreadSheet.month")}
            </TableCell>
            {monthKeys.map((key, i) => {
              return (
                <TableCell className={classes.tableCell} key={i}>
                  {key}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((data, i) => {
            return (
              <TableRow key={"table_" + i}>
                <TableCell sx={{ width: "8%" }} className={classes.tableCell}>
                  {data["name"]}
                </TableCell>
                <TableCell sx={{ width: "2%" }} className={classes.tableCell}>
                  {data["year"]}
                </TableCell>
                <TableCell sx={{ width: "6%" }} className={classes.tableCell}>
                  {data["unit"]}
                </TableCell>
                {monthKeys.map((key, i) => {
                  return (
                    <TableCell className={classes.tableCell} key={i + "_"}>
                      {data[key] === null ||
                      data[key] === undefined ||
                      isNaN(data[key])
                        ? ""
                        : CommonUtils.formatNumber(data[key] * props.multiple)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpreadSheet;
